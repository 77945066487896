<template>
  <div>
    <error-logs />
  </div>
</template>

<script>
import ErrorLogs from '@/components/InternalRecords/ErrorLogs.vue'

export default {
  components: { ErrorLogs },
  name: 'ErrorLog',
}
</script>
